import styled, { css } from 'styled-components'
import tw from 'twin.macro'
import { getTextColor } from '../../../../styles/global.styled'
import { TextAlign, TextColor, TextSize } from '../../../services/types'

export const Typography = styled.p<{
  size?: TextSize
  color?: TextColor
  textAlign?: TextAlign
  inlineBlock?: boolean
}>`
  ${({ size }) =>
    size &&
    css`
      font-size: ${size === 'xl'
        ? '1.25rem'
        : size === 'lg'
        ? '1.125rem'
        : size === 'md'
        ? '1rem'
        : size === 'smd'
        ? '0.975rem'
        : size === 'sm'
        ? '0.875rem'
        : size === 'xs'
        ? '0.75rem'
        : size === 'tiny'
        ? '0.625rem'
        : '1rem'};
    `}
  ${({ color }) =>
    color &&
    css`
      ${getTextColor(color)};
    `}

  ${({ textAlign }) =>
    textAlign &&
    css`
      text-align: ${textAlign};
    `}

  ${({ inlineBlock }) =>
    inlineBlock &&
    css`
      ${tw`inline-block`}

      .svg-icon {
        ${tw`mr-1.5`}
      }

      .svg-icon,
      > span {
        ${tw`inline-block align-middle`}
      }
    `}
`
