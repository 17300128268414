import { HTMLAttributes } from 'react'
import { TextAlign, TextColor, TextSize } from '../../../services/types'
import * as S from './Typography.styled'

export interface TypographyProps extends HTMLAttributes<HTMLParagraphElement> {
  size?: TextSize
  color?: TextColor
  textAlign?: TextAlign
  inlineBlock?: boolean
}

const Typography = (props: TypographyProps) => {
  const { children, color, size, textAlign, inlineBlock } = props
  return (
    <S.Typography
      {...props}
      size={size}
      color={color}
      textAlign={textAlign}
      inlineBlock={inlineBlock}
    >
      {children}
    </S.Typography>
  )
}

Typography.defaultProps = {
  size: 'md',
}

export default Typography
