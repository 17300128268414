import styled from 'styled-components'
import tw from 'twin.macro'
import { BackgroundColor, TextColor } from '../src/services/types'

export const getTextColor = (color: TextColor) => {
  switch (color) {
    case 'white':
      return tw`text-white`
    case 'greyDarker':
      return tw`text-greyDarker`
    case 'blueBayoux':
      return tw`text-blueBayoux`
    case 'grey':
      return tw`text-grey`
    case 'greyLight':
      return tw`text-greyLight`
    case 'success':
      return tw`text-success`
    case 'warning':
      return tw`text-warning`
    case 'danger':
      return tw`text-danger`
    case 'primary':
      return tw`text-primary`
    case 'greenLighter':
      return tw`text-greenLighter`
    default:
      return ''
  }
}

export const getBackground = (background: BackgroundColor) => {
  switch (background) {
    case 'lavenderGrey':
      return tw`bg-lavenderGrey text-greyLight`
    case 'azureishWhite':
      return tw`bg-azureishWhite text-greyLight`
    case 'concrete':
      return tw`bg-concrete text-greyLight`
    case 'ghostWhite':
      return tw`bg-ghostWhite text-greyLight`
    case 'successLight':
      return tw`bg-successLight text-success`
    case 'warningLight':
      return tw`bg-warningLight text-warning`
    case 'dangerLight':
      return tw`bg-dangerLight text-danger`
    case 'primaryLight':
      return tw`bg-primaryLight text-primary`
    case 'success':
      return tw`bg-success text-white`
    case 'warning':
      return tw`bg-warning text-white`
    case 'warningDark':
      return tw`bg-warningDark text-greyDarker`
    case 'danger':
      return tw`bg-danger text-white`
    case 'primary':
      return tw`bg-primary text-white`
    case 'white':
      return tw`bg-white text-greyDarker`
    default:
      return ''
  }
}

export const Space = styled.div<{
  md?: boolean
  lg?: boolean
  sm?: boolean
  xs?: boolean
}>`
  ${({ lg }) => lg && tw`h-10`}
  ${({ md }) => md && tw`h-8`}
  ${({ sm }) => sm && tw`h-4`}
  ${({ xs }) => xs && tw`h-2`}
`

export const Flex = styled.div<{
  itemsCenter?: boolean
  justifyBetween?: boolean
  justifyCenter?: boolean
}>`
  ${tw`flex`}
  ${({ itemsCenter }) => itemsCenter && tw`items-center`}
  ${({ justifyBetween }) => justifyBetween && tw`justify-between`}
  ${({ justifyCenter }) => justifyCenter && tw`justify-center`}
`

export const FlexResponsive = styled.div`
  ${tw`flex flex-col items-center sm:flex-row sm:items-center sm:justify-between`}
`

export const NoResultsMsg = styled.div`
  ${tw`mb-5`}
`
