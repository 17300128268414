import * as S from './index.styled'

interface LoadingProps {
  message?: string
  backdropBlur?: boolean
}

const Loading = (props: LoadingProps) => {
  const { message, backdropBlur } = props
  return (
    <S.Loading className='loading'>
      <S.LoadingIcon />
      {message && <S.LoadingMessage>{message}</S.LoadingMessage>}
    </S.Loading>
  )
}

Loading.defaultProps = {}

export default Loading
