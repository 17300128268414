import Link from 'next/link'
import { ButtonHTMLAttributes } from 'react'
import Loading from '@Global/Loading'
import * as S from './Button.styled'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string
  type?: 'submit' | 'reset' | 'button'
  border?: boolean
  greyMedium?: boolean
  success?: boolean
  successLight?: boolean
  warning?: boolean
  danger?: boolean
  dangerMedium?: boolean
  dangerLight?: boolean
  primary?: boolean
  primaryLight?: boolean
  social?: boolean
  rounded?: boolean
  noShadow?: boolean
  block?: boolean
  size?: 'md' | 'lg' | 'sm'
  href?: string
  target?: string
  iconPosition?: 'left' | 'right'
  active?: boolean
  loading?: boolean
}

const Button = (props: ButtonProps) => {
  const { children, text, href, target, loading } = props
  const linkProps = {
    border: props.border,
    greyMedium: props.greyMedium,
    success: props.success,
    successLight: props.successLight,
    warning: props.warning,
    danger: props.danger,
    dangerMedium: props.dangerMedium,
    dangerLight: props.dangerLight,
    primary: props.primary,
    primaryLight: props.primaryLight,
    rounded: props.rounded,
    social: props.social,
    block: props.block,
    noShadow: props.noShadow,
    size: props.size || 'md',
    iconPosition: props.iconPosition,
    style: props.style,
  }

  return href ? (
    <Link
      href={href}
      passHref
      className={props.active ? 'active' : ''}
      target={target}
    >
      <S.ButtonLink {...linkProps}>{text || children}</S.ButtonLink>
    </Link>
  ) : (
    <S.Button
      {...props}
      className={
        props.active
          ? `active ${props.className ?? ''}`
          : `${props.className ?? ''}`
      }
      type={props.type || 'button'}
    >
      {loading && <Loading />}
      {text || children}
    </S.Button>
  )
}

export default Button
