import styled, { css } from 'styled-components'
import tw from 'twin.macro'

const buttonStyle = css<{
  greyMedium?: boolean
  primary?: boolean
  primaryLight?: boolean
  success?: boolean
  successLight?: boolean
  warning?: boolean
  danger?: boolean
  dangerMedium?: boolean
  dangerLight?: boolean
  disabled?: boolean
  border?: boolean
  rounded?: boolean
  block?: boolean
  noShadow?: boolean
  iconPosition?: 'right' | 'left'
  size?: 'md' | 'lg' | 'sm'
  social?: boolean
}>`
  ${tw`text-greyDarker cursor-pointer font-semibold text-center whitespace-nowrap justify-center items-center text-base rounded-base disabled:opacity-50 bg-ghostWhite inline-flex transition duration-300 relative`}

  svg, > span {
    ${tw`inline-block`}
  }

  &:focus {
    ${tw`outline-none`}
  }

  &:hover {
    ${tw`bg-greyDarker border-greyDarker text-white no-underline`}
  }
  ${({ greyMedium }) =>
    greyMedium && tw`border bg-concrete border-concrete text-greyDarker`}
  ${({ primary }) => primary && tw`bg-primary border-primary text-white`}
  ${({ primaryLight }) =>
    primaryLight && tw`border bg-primaryLight border-primaryLight text-primary`}
  ${({ success }) => success && tw`bg-success border-success text-white`}
  ${({ successLight }) =>
    successLight && tw`border bg-successLight border-successLight text-success`}
  ${({ warning }) => warning && tw`border bg-warning border-warning text-white`}
  ${({ danger }) => danger && tw`border bg-danger border-danger text-white`}
  ${({ dangerLight }) =>
    dangerLight && tw`border bg-dangerLight border-danger text-danger`}
  ${({ dangerMedium }) =>
    dangerMedium && tw`border bg-dangerMedium border-dangerMedium text-white`}
  ${({ disabled }) => disabled && tw`cursor-not-allowed`}
  ${({ border }) =>
    border &&
    css`
      ${tw`border border-greyLight text-greyDarker bg-white`}

      &:hover {
        ${tw`bg-greyLight text-white`}
      }
    `}

  ${({ social, border }) =>
    social &&
    css`
      ${tw`bg-ghostWhite border-ghostWhite`}
      &:hover {
        ${tw`bg-azureishWhite border-azureishWhite text-blueBayoux`}
      }

      ${border &&
      css`
        ${tw`bg-white border-azureishWhite`}
      `}
    `}

  ${({ noShadow }) =>
    noShadow &&
    css`
      box-shadow: none;
    `}

  ${({ border, warning }) =>
    border &&
    warning &&
    css`
      ${tw`border-warning text-warning`}

      &:hover,
      &.active {
        ${tw`bg-warning text-white`}
      }
    `}

  ${({ border, success }) =>
    border &&
    success &&
    css`
      ${tw`border-success text-success`}

      &:hover,
      &.active {
        ${tw`bg-success text-white`}
      }
    `}

  ${({ border, danger }) =>
    border &&
    danger &&
    css`
      ${tw`border-danger text-danger`}

      &:hover,
      &.active {
        ${tw`bg-danger text-white`}
      }
    `}

  ${({ border, primary }) =>
    border &&
    primary &&
    css`
      ${tw`border-primary text-primary`}

      &:hover,
      &.active {
        ${tw`bg-primary text-white`}
      }
    `}

  ${({ rounded }) =>
    rounded &&
    css`
      ${tw`p-0 w-10 h-10 flex items-center justify-center`}
      svg {
        ${tw`ml-0`}
      }
    `}
  ${({ block }) => block && tw`w-full`}
  ${({ iconPosition, size }) =>
    iconPosition && iconPosition === 'left'
      ? css`
          ${tw`py-2.5 inline-flex items-center`}

          .svg-icon {
            font-size: 0;
          }

          svg {
            ${tw`mr-3`}
          }

          ${size &&
          size === 'sm' &&
          css`
            svg {
              ${tw`mr-1`}
            }
          `}
        `
      : iconPosition === 'right'
      ? css`
          ${tw`py-2.5 inline-flex items-center`}

          .svg-icon {
            font-size: 0;
          }

          svg {
            ${tw`ml-3`}
          }

          ${size &&
          size === 'sm' &&
          css`
            svg {
              ${tw`ml-1`}
            }
          `}
        `
      : null}

    ${({ size }) =>
    size === 'md' &&
    css`
      ${tw`py-1 px-6 text-sm`}
      min-height: 46px;
    `}
    ${({ size }) => size === 'sm' && tw`py-1.5 px-3 text-xs`}
    ${({ size }) => size === 'lg' && tw`py-3.5 px-4`}

  .loading {
    ${tw`relative self-auto inline-block mr-2.5`}
    width: 25px;
    height: 25px;

    > div {
      ${tw`mb-0`}
      width: 25px;
      height: 25px;
      border-width: 3px;
    }
  }
`

export const Button = styled.button`
  ${buttonStyle}
`

export const ButtonLink = styled.span`
  ${buttonStyle}
`
